import schiebetuer_00 from '../images/schiebetuer_00_400.webp'
import teleskopschiebetuer_00 from '../images/teleskopschiebetuer_00_400.webp'
import rc2_Schiebetuer_00 from '../images/rc2_schiebetuer_00_400.webp'
import breakout_00 from '../images/breakout_00_400.webp'
import ganzglas_schiebetuer_00 from '../images/ganzglas_schiebetuer_00_400.webp'
import drehtuer_00 from '../images/automatische_drehtuer_00_400.webp'
import karusselltuer_00 from '../images/karusselltuer_00_400.webp'
import rundbogenschiebetuer_00 from '../images/rundbogenschiebetuer_00_400.webp'
import glasschiebewand_00 from '../images/glasschiebewand_00_400.webp'
import faltfluegeltuer_00 from '../images/faltfluegel_00_400.webp'
import bauelemente_00 from '../images/bauelemente_00_400.webp'

export default [
  {
    title: 'Automatische Schiebetür',
    description: 'Öffnet leise, berührungslos und lässt sich in jede Umgebung intergrieren.',
    image: schiebetuer_00,
    productPage: '/produkte/automatische-schiebetuer/'
  },
  {
    title: 'Automatische Teleskop&#173;schiebetür',
    description: 'Größte Öffnungsweite, hygenisch, sicher und komfortabel.',
    image: teleskopschiebetuer_00,
    productPage: '/produkte/automatische-teleskopschiebetuer/'
  },
  {
    title: 'Einbruchhemmende Automatik&#173;schiebetür',
    description: 'Höchste Sicherheit für Ihren Gebäudeschutz bei voller Barrierefreiheit.',
    image: rc2_Schiebetuer_00,
    productPage: '/produkte/einbruchhemmende-automatikschiebetuer/'
  },
  {
    title: 'Break-Out Automatik&#173;schiebetür',
    description: '24/7 im Flucht- und Rettungsweg zugelassen und trotzdem verriegelt.',
    image: breakout_00,
    productPage: '/produkte/break-out-automatikschiebetuer/'
  },
  {
    title: 'Ganzglas Automatik&#173;schiebetür',
    description: 'Rahmenlos mit schlankem Design, passend in jede Architektur.',
    image: ganzglas_schiebetuer_00,
    productPage: '/produkte/ganzglas-automatikschiebetuer/'
  },
  {
    title: 'Automatische Drehtür',
    description: 'Barrierefreier Zugang im Innen- und Außenbereich, universell für jegliche Art von Türen, auch zur Nachrüstung.',
    image: drehtuer_00,
    productPage: '/produkte/automatische-drehtuer/'
  },
  {
    title: 'Karussell&#173;drehtür',
    description: 'Repräsentativer Eingang mit Funktion, wirkt Zugluft, Temperaturschwankungen, Lärm und Schmutz entgegen.',
    image: karusselltuer_00,
    productPage: '/produkte/karusselldrehtuer/'
  },
  {
    title: 'Rundbogen&#173;schiebetür',
    description: 'Die optisch hochwertige Fassadenlösungen, mit allen Vorteilen einer automatischen Schiebetür.',
    image: rundbogenschiebetuer_00,
    productPage: '/produkte/rundbogen-schiebetuer/'
  },
  {
    title: 'Automatische Faltflügeltür',
    description: 'Raumwunder für spezielle Fälle. Öffnet automatisch, zuverlässig und platzsparend.',
    image: faltfluegeltuer_00,
    productPage: '/produkte/automatische-faltfluegeltuer/'
  },
  {
    title: 'Glas&#173;schiebewand',
    description: 'Individuelle und transparente Raumtrennsysteme, die sich vollständig ohne Schwelle öffnen lassen.',
    image: glasschiebewand_00,
    productPage: '/produkte/glasschiebewand/'
  },
  {
    title: 'Bauelemente',
    description: 'Individuelle Festverglasungen geeignet für Neubau oder Modernisierung, angepasst an in Ihre Einbausituation.',
    image: bauelemente_00,
    productPage: '/produkte/bauelemente/'
  },
];
