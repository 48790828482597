import referenz_01 from '../images/referenzkunde_1_150.webp';
import referenz_02 from '../images/referenzkunde_2_150.webp';
import referenz_03 from '../images/referenzkunde_7_150.webp';
import referenz_04 from '../images/referenzkunde_8_150.webp';

export default [
  {
    title: '"Eine hervoragende Beratung, kompetent und freundlich"',
    content:
      'Lösung: Umbau einer manuellen Flügeltür zur automatischen Schiebetür',
    customerName: 'Sonnen Apotheke',
    customerTitle: 'Inhaber',
    customerImage: referenz_01
  },
  {
    title: '"Schnelle und wirtschaftliche Instandsetzung"',
    content:
      'Lösung: Einbau eines Modernisierungs-Kit in alte defekte Automatiktür',
    customerName: 'Parfümerie Rist',
    customerTitle: 'Filialleitung',
    customerImage: referenz_03
  },
  {
    title: '"Neutrale Bewertung unserer alten Türanlagen"',
    content:
      'Lösung: Erstellung einer Risikoanalyse auf Basis DIN 18650 und DIN/EN 16005',
    customerName: 'Großbäckerei Kessler',
    customerTitle: 'Haustechnik',
    customerImage: referenz_02
  },
  {
    title: '"Online und einfach - Rund um sorglos Paket"',
    content:
      'Lösung: Ein Ansprechpartner von Planung bis zur Fertigstellung',
    customerName: 'Planungsbüro K&M',
    customerTitle: 'Architektin',
    customerImage: referenz_04
  }
];
