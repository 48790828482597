import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import CustomerCard from '../components/CustomerCard';
import ProductCard from '../components/ProductCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import productData from '../data/product-data';
import beratung_01 from '../images/service_planung.webp';
import modernisierung_01 from '../images/service_modernisierung.webp';
import normen_01 from '../images/service_normen.webp';
import service_01 from '../images/service_kundendienst.webp';
import review from '../svg/review.svg';
import agent from '../svg/agent.svg';
import target from '../svg/target.svg';
import header_01 from '../images/automatiktuer24_header_01.webp';
import konfigurator from '../images/konfigurator_1200.webp';

const Index = () => (
  <div className="application">

    <Helmet>
      <title>Automatiktüren einfach online kaufen | Automatiktür24</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Automatiktüren einfach online kaufen" />
      <html lang="de" />
    </Helmet>

    <Layout>
      <section id="start" className="pt-8 md:pt-32 lg:pt-24 md:pb-10">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-bold leading-none lg:w-3/4">
              Ihre Automatiktür online konfigurieren.
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Die 3-Minuten-Beratung. Kostenlos und unverbindlich die richtige Tür finden.
            </p>
            <p className="mt-8 mb-6 md:mt-12">
              <Link to="/automatiktuer-konfigurator/" className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white py-4 px-6 sm:px-12 rounded shadow-lg">Automatiktür konfigurieren</Link>
            </p>
          </div>
          <div className="pt-8 lg:w-1/2 lg:pt-0">
            <img src={header_01} alt="Automatische Schiebetüren" className="mx-auto img870" />
          </div>
        </div>
      </section>

      <section id="produkte" className="py-20 lg:pb-20 lg:pt-24">
        <div className="container mx-auto items-center">
          <LabelText className="mb-8 text-gray-600 text-center">Produkte</LabelText>
          <div className="flex flex-wrap flex-stretch sm:flex-row mt-12 max-w-6xl mx-8">
            {productData.map(product => (
              <ProductCard product={product} key={product.productPage} />
            ))}
          </div>
        </div>
      </section>

      <section id="service" className="py-20 lg:pb-10 lg:pt-24">
        <LabelText className="text-gray-600 text-center">Service</LabelText>
        <SplitSection
          primarySlot={
            <div className="lg:pr-16 xl:pr-20">
              <h3 className="text-2xl font-semibold leading-tight">Planung im Neubau</h3>
              <p className="mt-8 text-lg font-light leading-relaxed">
                Wir finden für Sie, in der Vielzahl an Türsystemen, die beste an Ihre Örtlichkeiten angepasste Lösung für Barrierefreiheit, Hygiene, Optik und Sicherheit. Lassen Sie sich jetzt von uns beraten oder nutzen Sie unseren Online - Konfigurator.
                <br/>
                <br/>
                Planung - Beratung - Risikobewertung - Montage - Automatiktür24 Ihr Partner für automatische Türsysteme
              </p>
            </div>
          }
          secondarySlot={<img src={beratung_01} alt="Beratung" className="rounded-lg shadow-xl img580" />}
        />

        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-16 xl:pl-20">
              <h3 className="text-2xl font-semibold leading-tight">Modernisierung und Umrüstung</h3>
              <p className="mt-8 text-lg font-light leading-relaxed">
              In unterschiedlichen Ausbaustufen kann Ihre alte Türanlage auf den neusten Stand der Technik umgebaut werden. Individuell prüfen wir ob Nachrüstung der Sensorik, Austausch der Antriebstechnik mit alten Türflügeln oder ein Komplettsystem die wirtschaftlichste Lösung ist.
              </p>
            </div>
          }
          secondarySlot={<img src={modernisierung_01} alt="Modernisierung" className="rounded-lg shadow-xl img580" />}
        />

        <SplitSection
          primarySlot={
            <div className="lg:pr-16 xl:pr-20">
              <h3 className="text-2xl font-semibold leading-tight">Normen und Richtlinien</h3>
              <p className="mt-8 text-lg font-light leading-relaxed">
                Sie interessieren sich für die geltenden Richtlinien und Normen? Erfahren Sie bei uns mehr über DIN 18650, DIN/EN 16005, AutSchR, EltVTR, ASR 1.7, Risikoanalyse und Bestandsschutz.
              </p>
            </div>
          }
          secondarySlot={<img src={normen_01} alt="Service" className="rounded-lg shadow-xl img580" />}
        />

        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-16 xl:pl-20">
              <h3 className="text-2xl font-semibold leading-tight">Service und Wartung</h3>
              <p className="mt-8 text-lg font-light leading-relaxed">
                Sie legen Wert auf Zuverlässigkeit? Durch die Wartung Ihrer automatischen Türen werden
                nicht nur Funktion und Sicherheit zur Einhaltung der gesetzlichen Normen und Richtlinien
                sichergestellt, auch Verschleiß oder Mängel werden frühzeitig erkannt und behoben.
              </p>
            </div>
          }
          secondarySlot={<img src={service_01} alt="Modernisierung" className="rounded-lg shadow-xl img580" />}
        />
      </section>

      <section id="referenzen" className="py-20 lg:pb-20 lg:pt-24">
        <div className="container mx-auto items-center">
          <LabelText className="mb-8 text-gray-600 text-center">Was sagen unsere Kunden</LabelText>
          <div className="flex flex-wrap flex-stretch sm:flex-row mt-12 max-w-6xl mx-8">
            {customerData.map(customer => (
              <CustomerCard customer={customer} key={customer.customerTitle}/>
            ))}
          </div>
        </div>
      </section>

      <section id="das-ist-uns-wichtig" className="py-20 lg:pt-32">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Das ist uns wichtig</LabelText>
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <div className="w-full sm:w-1/3 flex flex-col items-center">
              <StatsBox primaryText="Ein Ziel" secondaryText="Ihre Automatiktür mit höchster Qualität" />
              <img src={target} alt="Automatiktür Qualität" className="w-40 justify-self-center"/>
            </div>
            <div className="w-full sm:w-1/3 flex flex-col items-center">
              <StatsBox primaryText="24h" secondaryText="Individuelle online Kundenberatung" />
              <img src={agent} alt="Automatiktür Kundensupport" className="w-40 justify-self-center"/>
            </div>
            <div className="w-full sm:w-1/3 flex flex-col items-center">
              <StatsBox primaryText="100%" secondaryText="Zufriedene Kunden. Zuverlässige Türen." />
              <img src={review} alt="Zufriedene Automatiktür-Kunden" className="w-40 justify-self-center"/>
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center" style={{backgroundImage: `url(${konfigurator})`, backgroundSize: 'cover'}}>
        <h3 className="text-5xl font-semibold text-white text-shadow-md">Sind Sie bereit für Ihre neue Automatiktür?</h3>
        <p className="mt-8 text-xl text-white text-shadow-md">Kontaktlos. Hygienisch. Modern.</p>
        <p className="mt-2 text-xl text-white text-shadow-md">Stellen Sie in nur 3 Minuten Ihr individuelles Angebot zusammen.</p>
        <p className="mt-16">
          <Link
            to="/automatiktuer-konfigurator/"
            className="py-4 px-12 text-lg bg-blue-500 hover:bg-blue-600 text-white hover:text-white rounded shadow-md"
          >Jetzt Angebot erstellen</Link>
        </p>
      </section>

    </Layout>
  </div>
);

export default Index
