import React from 'react';
import Card from './Card';
import * as entities from 'entities';

const CustomerCard = ({ customer }) => (
  <div key={customer.customerTitle} className="flex px-3 xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full max-w-sm mb-8">
    <Card className="mb-2">
      <div className="h-56">
        <p className="text-xl font-semibold text-blue-700">{customer.title}</p>
        <p className="mt-6">{customer.content}</p>
      </div>
      <div className="flex items-center mt-8">
        <img
          className="w-16 h-16 mr-4 rounded-full"
          src={customer.customerImage}
          alt={customer.customerName}
        />
        <div>
          <p>{customer.customerName}</p>
          <p className="text-sm text-gray-600">{customer.customerTitle}</p>
        </div>
      </div>
    </Card>
  </div>
);

export default CustomerCard;
